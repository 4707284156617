const { string, shape, func, arrayOf, node } = require('prop-types');
const React = require('react');
const classnames = require('classnames');

const Card = require('@andes/card');

const { PLATFORM: { MP, MS } } = require('../../../services/data/enums');

const { CardFooter } = Card;

const CenterCard = ({
  children,
  type,
  helpInfo,
  platform,
  mercadoShopsData,
  wordingContext,
}) => {
  const IsShopContext = Boolean(mercadoShopsData);
  const shouldShowFooter = platform?.id !== MS && !IsShopContext && helpInfo && helpInfo.href;

  const centerCardBodyClassNames = classnames(
    'center-card__body',
    { 'center-card__body--reduced': type.includes('reduced') },
    { 'center-card__body--centered': type.includes('centered') },
    { 'center-card__body--loose': type.includes('loose') },
    { 'center-card__body--footerless': !shouldShowFooter },
  );

  const centerCardFooterClassNames = classnames(
    platform?.id === MP && 'center-card__footer--mp',
  );

  return (
    <div className="wrap">
      <div className="main center-card">
        <div className="center-card__billboard">
          {IsShopContext && (
            <>
              <p>{wordingContext?.payment_and_shipping}</p>
              <span className="nav-logo">{platform?.siteId}</span>
            </>
          )}
        </div>
        <Card className={centerCardBodyClassNames}>
          {children}
          {shouldShowFooter
            && (
            <CardFooter link={helpInfo} className={centerCardFooterClassNames}>
              {helpInfo.text}
            </CardFooter>
            )}
        </Card>
      </div>
    </div>
  );
};

CenterCard.propTypes = {
  children: node.isRequired,
  type: string,
  helpInfo: shape({
    href: string,
    text: string,
    onClick: func,
  }),
  platform: shape({
    id: string.isRequired,
    siteId: string.isRequired,
    home: shape({
      url: string.isRequired,
    }).isRequired,
  }),
  mercadoShopsData: shape({
    shop: shape({
      features: arrayOf(string),
    }).isRequired,
  }),
  wordingContext: shape({
    payment_and_shipping: string,
  }),
};

CenterCard.defaultProps = {
  type: '',
  helpInfo: null,
  mercadoShopsData: null,
  platform: null,
  wordingContext: null,
};

module.exports = CenterCard;
