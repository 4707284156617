require('../pages/email_nickname/_styles.scss');
/* globals document, window */
const React = require('react');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const hydrate = require('nordic/hydrate');

const EmailNickname = require('../pages/email_nickname/components/EmailNicknameView');
const DomainPreconnect = require('../components/domain-preconnect/DomainPreconnect');

const preloadState = window.__PRELOADED_STATE__;
const { translations } = preloadState;
const i18n = new I18n({ translations });

hydrate(
  <I18nProvider i18n={i18n}>
    <EmailNickname
      {...preloadState}
    />
  </I18nProvider>,
  document.getElementById('root-app'),
  () => {
    /* Added for e2e tests. Ensures the client got js and can run it */
    if (!preloadState.envIsProduction && window.Cypress) {
      window.appReady = true;
    }
  },
);

/* preconnects */
if (document.getElementById('preconnect-props')) {
  const preconnectProps = JSON.parse(document.getElementById('preconnect-props').getAttribute('data-props'));
  hydrate(
    React.createElement(DomainPreconnect, preconnectProps), document.getElementById('preconnect-app'),
  );
}
