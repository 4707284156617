import { SessionType } from '../../services/data/enums';

/**
 * @param  {import('nordic/i18n').I18n} i18n
 * @param  {string} context
 */
export const translateFactorBlockedPage = (i18n, context = SessionType.STANDARD) => ({
  /* i18n-block-start except: ["en", "pt-BR"] */
  title: i18n.gettext('Alcanzaste el límite de intentos para usar tu contraseña'),
  subtitle: {
    [SessionType.SCOPED]: i18n.gettext('Para continuar, usa otro método o cambia tu contraseña.'),
    [SessionType.STANDARD]: i18n.gettext('Para continuar, usa otro método.'),
    /* i18n-block-end */
  }[context],
  buttons: {
    /* i18n-next-line except: ["en"] */
    primary: i18n.gettext('Usar otro método'),
    secondary: {
      [SessionType.SCOPED]: i18n.gettext('Cambiar contraseña'),
      [SessionType.STANDARD]: i18n.gettext('Volver al inicio'),
    }[context],
  },
});
