const React = require('react');
const { string, bool, shape } = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const WordingManager = require('../wording-manager');

const RecaptchaTerms = ({ lang, hideTermAndConditions, required, i18n }) => {
  const recaptchaPrivacyUrl = `https://policies.google.com/privacy?hl=${lang}`;
  const recaptchaTermsUrl = `https://policies.google.com/terms?hl=${lang}`;
  const wording = WordingManager.wordings(i18n);

  return (
    !required && hideTermAndConditions
    && (
      <div className="recaptcha-terms__container">
        {wording.recaptcha.protected_by()}<span className="recaptcha-terms__links">
          - <a href={recaptchaPrivacyUrl}>{wording.recaptcha.privacy()} </a>
          - <a href={recaptchaTermsUrl}>{wording.recaptcha.conditions()}</a>
        </span>
      </div>
    )
  );
};

RecaptchaTerms.propTypes = {
  lang: string.isRequired,
  hideTermAndConditions: bool.isRequired,
  required: bool.isRequired,
  i18n: shape().isRequired,
};

module.exports = injectI18n(RecaptchaTerms);
