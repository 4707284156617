/* eslint-disable max-len */
const { help } = require('./help');
const { translateSecurityIssues } = require('./securityIssues');

const actionsForNotRegisteredPage = i18n => ({
  primary: () => i18n.gettext('Crear cuenta'),
  /* i18n-next-line except: ["en"] */
  secondary: () => i18n.gettext('Iniciar sesión'),
});

const actionsForIdentifyPage = i18n => ({
  submit: () => i18n.gettext('Continuar'),
  register: () => i18n.gettext('Crear cuenta'),
});

const actionsForPasswordPage = i18n => ({
  submit: () => i18n.gettext('Iniciar sesión'),
  recover_pass: () => i18n.gettext('¿Olvidaste tu contraseña?'),
});

const actionsForChangePasswordPage = i18n => ({
  continue: () => i18n.gettext('Ahora no'),
  change_password: () => i18n.gettext('Cambiar mi contraseña'),
});

const actionsForReauthPasswordPage = i18n => ({
  submit: () => i18n.gettext('Continuar'),
  recover_pass: () => i18n.gettext('¿Olvidaste tu contraseña?'),
});

const actionForFactorBlockedPage = i18n => ({
  /* i18n-next-line except: ["en"] */
  primary: i18n.gettext('Ingresar con otro método'),
  secondary: i18n.gettext('Volver al inicio'),
});

const defaultPassword = (i18n) => ({
  buttons: actionsForPasswordPage(i18n),
  /* i18n-next-line except: ["en"] */
  title: (_, platformName) => i18n.gettext('Ingresa tu contraseña de {0}', platformName),
});

const defaultWording = (i18n, type) => ({
  registered: {
    type,
    email_or_nickname: {
      title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone: {
      /* i18n-next-line except: ["en"] */
      title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone_or_social_login: {
      /* i18n-next-line except: ["en"] */
      title: () => i18n.gettext('Ingresa tu e-mail, teléfono o usuario para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone_or_social_login_with_recaptcha: {
      /* i18n-next-line except: ["en"] */
      title: () => i18n.gettext('Ingresa tu e-mail, teléfono o usuario para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    password: defaultPassword(i18n),
  },
  not_registered: {
    type,
    not_registered: {
      title: () => i18n.gettext('Ingresa a tu cuenta'),
      buttons: actionsForNotRegisteredPage(i18n),
    },
    email_or_nickname: {
      title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone: {
      /* i18n-next-line except: ["en"] */
      title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone_or_social_login: {
      /* i18n-next-line except: ["en"] */
      title: () => i18n.gettext('Ingresa tu e-mail, teléfono o usuario para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone_or_social_login_with_recaptcha: {
      /* i18n-next-line except: ["en"] */
      title: () => i18n.gettext('Ingresa tu e-mail, teléfono o usuario para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    password: defaultPassword(i18n),
  },
  password_greet: {
    type,
    password: {
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Ingresa tu contraseña de {1}', [user, platformName]),
      buttons: actionsForPasswordPage(i18n),
    },
  },
  password_reauth: {
    type,
    password: {
      title: () => i18n.gettext('Para continuar ingresa tu contraseña'),
      buttons: actionsForReauthPasswordPage(i18n),
    },
  },
});

const defaultMshopStoreWording = (i18n, type) => ({
  type,
  email_or_nickname: {
    /* i18n-block-start except: ["en"] */
    title: () => i18n.gettext('Compra con tu cuenta de Mercado Libre'),
    subtitle: store => i18n.gettext('{0} utiliza la plataforma de pagos y la logística de envíos de Mercado Libre para que tu compra esté protegida.', store),
    buttons: actionsForIdentifyPage(i18n),
    payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
  },
  email_or_nickname_or_phone: {
    title: () => i18n.gettext('Compra con tu cuenta de Mercado Libre'),
    subtitle: store => i18n.gettext('{0} utiliza la plataforma de pagos y la logística de envíos de Mercado Libre para que tu compra esté protegida.', store),
    buttons: actionsForIdentifyPage(i18n),
    payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
  },
  password: {
    ...defaultPassword(i18n),
    payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
    /* i18n-block-end */
  },
});

const assembleWording = i18n => ({
  labels: {
    email_or_nickname: () => i18n.gettext('E-mail o usuario'),
    /* i18n-next-line except: ["en"] */
    email_or_nickname_or_phone: () => i18n.gettext('E‑mail, teléfono o usuario'),
    /* i18n-next-line except: ["en"] */
    email_or_nickname_or_phone_or_social_login: () => i18n.gettext('E‑mail, teléfono o usuario'),
    /* i18n-next-line except: ["en"] */
    email_or_nickname_or_phone_or_social_login_with_recaptcha: () => i18n.gettext('E‑mail, teléfono o usuario'),
  },
  help: help(i18n),
  securityIssues: translateSecurityIssues(i18n),
  registered: {
    default: defaultWording(i18n, 'default').registered,
    explicit: defaultWording(i18n, 'login').registered,
    registration_v0: defaultWording(i18n, 'registration_v0').registered,
    registration_v3: defaultWording(i18n, 'registration_v3').registered,
    favorite: {
      type: 'favorite',
      email_or_nickname: {
        /* i18n-block-start except: ["en"] */
        title: (platformName) => i18n.gettext('¡Hola! Para agregar favoritos, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para agregar favoritos, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    question: {
      type: 'question',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para preguntar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para preguntar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    quote: {
      type: 'quote',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para cotizar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para cotizar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    share: {
      type: 'share',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para compartir, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para compartir, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    print: {
      type: 'print',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para imprimir, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para imprimir, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    publish: {
      type: 'publish',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para publicar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para publicar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    download: {
      type: 'download',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para descargar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para descargar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    contact: {
      type: 'contact',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para contactar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para contactar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    sell: {
      type: 'sell',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para vender, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para vender, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    buy: {
      type: 'buy',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    view_cart: {
      type: 'view_cart',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para ver el carrito, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para ver el carrito, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        /* i18n-block-end */
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    add_cart: {
      type: 'add_cart',
      email_or_nickname: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: (platformName) => i18n.gettext('¡Hola! Para agregar al carrito, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-block-start except: ["en"] */
        title: (platformName) => i18n.gettext('¡Hola! Para agregar al carrito, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_buy: {
      type: 'checkout_buy',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_auction: {
      type: 'checkout_auction',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para ofertar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para ofertar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_real_state: {
      type: 'advance_real_state',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_services: {
      type: 'advance_services',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_motors: {
      type: 'advance_motors',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para reservar ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    callme_real_state: {
      type: 'callme_real_state',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para pedir que te llamen, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para pedir que te llamen, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    point_redeem_coupon: defaultWording(i18n, 'point_redeem_coupon').registered,
    google_play: defaultWording(i18n, 'google_play').registered, // not used but to avoid 500
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store'),
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk'),
    consumer_credits: {
      type: 'consumer_credits',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('¡Hola! Para activar Mercado Crédito, primero ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para activar Mercado Crédito, primero ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        /* i18n-block-end */
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    negative_traffic: {
      type: 'negative_traffic',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    cbt: defaultWording(i18n, 'cbt').registered,
    point_buy: {
      type: 'point_buy',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        buttons: actionsForReauthPasswordPage(i18n),
        title: defaultPassword(i18n).title,
      },
    },
    vis_motor_contact: defaultWording(i18n, 'vis_motor_contact').registered,
    vis_credits_simulation: {
      type: 'vis_credits_simulation',
      email_or_nickname: {
        /* i18n-block-start except: ["en"] */
        title: (platformName) => i18n.gettext('¡Hola! Para simular tu crédito, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para simular tu crédito, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        /* i18n-block-end */
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    faqs: defaultWording(i18n, 'faqs').registered,
    digital_sus: {
      type: 'digital_sus',
      email_or_nickname: {
        /* i18n-block-start except: ["en"] */
        title: (platformName) => i18n.gettext('¡Hola! Para activar tus beneficios, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('¡Hola! Para activar tus beneficios, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        title: (_, platformName) => i18n.gettext('Para activar tus beneficios, ingresa tu contraseña de {0}', platformName),
        buttons: actionsForPasswordPage(i18n),
      },
      /* i18n-block-end */
    },
    ifpe: {
      type: 'ifpe',
      email_or_nickname: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: (platformName) => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: (platformName) => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    registration_link: defaultWording(i18n, 'registration_link').registered,
    checkout_pro: defaultWording(i18n, 'checkout_pro').registered,
  },
  not_registered: {
    default: defaultWording(i18n, 'default').not_registered,
    explicit: defaultWording(i18n, 'login').not_registered,
    registration_v0: defaultWording(i18n, 'registration_v0').not_registered,
    registration_v3: defaultWording(i18n, 'registration_v3').not_registered,
    favorite: {
      type: 'favorite',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para agregar favoritos, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    question: {
      type: 'question',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para preguntar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    quote: {
      type: 'quote',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para cotizar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    share: {
      type: 'share',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para compartir, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    print: {
      type: 'print',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para imprimir, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    publish: {
      type: 'publish',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para publicar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    download: {
      type: 'download',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para descargar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    contact: {
      type: 'contact',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para contactar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    sell: {
      type: 'sell',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para vender, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    buy: {
      type: 'buy',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para comprar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    view_cart: {
      type: 'view_cart',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para ver el carrito, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    add_cart: {
      type: 'add_cart',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para agregar al carrito, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_buy: {
      type: 'checkout_buy',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para comprar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_auction: {
      type: 'checkout_auction',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para ofertar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_real_state: {
      type: 'advance_real_state',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para reservar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_services: {
      type: 'advance_services',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para reservar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_motors: {
      type: 'advance_motors',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para reservar, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    callme_real_state: {
      type: 'callme_real_state',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para pedir que te llamen, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    point_redeem_coupon: {
      type: 'point_redeem_coupon',
      not_registered: {
        title: () => i18n.gettext('Crea una cuenta o ingresa con tu usuario de Mercado Pago o Mercado Libre'),
        buttons: {
          primary: () => i18n.gettext('Crear cuenta'),
          secondary: () => i18n.gettext('Ingresar con mi usuario'),
        },
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    google_play: { // not used but to avoid 500
      type: 'google_play',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store'),
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk'),
    consumer_credits: {
      type: 'consumer_credits',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para activar Mercado Crédito, primero ingresa a tu cuenta'),
        buttons: {
          primary: () => i18n.gettext('Crear cuenta'),
          secondary: () => i18n.gettext('Ingresar con mi usuario'),
        },
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    negative_traffic: {
      type: 'negative_traffic',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    cbt: defaultWording(i18n, 'cbt').not_registered,
    point_buy: {
      type: 'point_buy',
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        buttons: actionsForReauthPasswordPage(i18n),
        title: defaultPassword(i18n).title,
      },
    },
    vis_motor_contact: {
      type: 'vis_motor_contact',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para contactar al vendedor, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    vis_credits_simulation: {
      type: 'vis_credits_simulation',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para simular tu crédito, inicia sesión en tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: (platformName) => i18n.gettext('Ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    faqs: defaultWording(i18n, 'faqs').not_registered,
    digital_sus: {
      type: 'digital_sus',
      not_registered: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para activar tus beneficios, ingresá a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: (platformName) => i18n.gettext('¡Hola! Para activar tus beneficios, ingresá tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: (platformName) => i18n.gettext('¡Hola! Para activar tus beneficios, ingresá tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        title: defaultPassword(i18n),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    ifpe: {
      type: 'ifpe',
      email_or_nickname: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: (platformName) => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: (platformName) => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail, teléfono o usuario de {0}', platformName),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    registration_link: defaultWording(i18n, 'registration_link').not_registered,
    checkout_pro: defaultWording(i18n, 'checkout_pro').not_registered,
  },
  factor_blocked: {
    title: i18n.gettext('Alcanzaste el límite de intentos para ingresar con tu contraseña'),
    subtitle: i18n.gettext('No te preocupes, puedes ingresar con otro método de verificación.'),
    buttons: actionForFactorBlockedPage(i18n),
  },
  manual_removal: {
    title: i18n.gettext('Por seguridad, bloqueamos el acceso a tu cuenta'),
    subtitle: i18n.gettext('Bloqueamos momentáneamente tu cuenta porque detectamos un ingreso sospechoso y es probable que la estén usando sin tu permiso. Te contactaremos lo antes posible para confirmar tu identidad y desbloquearla de forma segura.'),
    buttons: {
      primary: i18n.gettext('Volver al inicio'),
    },
  },
  congrats_greet: {
    title: user => i18n.gettext('¡Listo, {0}!', user),
    subtitle: i18n.gettext('¿Quieres cambiar tu contraseña ahora?'),
    buttons: actionsForChangePasswordPage(i18n),
  },
  social_off: {
    /* i18n-next-line except: ["en"] */
    title: provider => i18n.gettext('El acceso a través de {0} ya no está disponible', provider),
    /* i18n-next-line except: ["en"] */
    subtitle: provider => i18n.gettext(
      'Ingresa con el e-mail vinculado a tu cuenta de {0} y selecciona la opción No sé mi clave para crear una nueva clave.', // eslint‑disable‑line max‑len
      provider,
    ),
  },
  expired_password: {
    title: i18n.gettext('Tu clave venció'),
    last_time_description: i18n.gettext('Pasó mucho tiempo desde la última vez que la usaste.'),
    use_other_method_description: i18n.gettext('No te preocupes, puedes ingresar con otro método de verificación.'),
    buttons: {
      continue: i18n.gettext('Continuar'),
    },
  },
  errors: {
    email_not_found: () => i18n.gettext('Revisa tu e-mail o usuario.'),
    nickname_not_found: () => i18n.gettext('Revisa tu e-mail o usuario.'),
    /* i18n-next-line except: ["en"] */
    phone_not_found: () => i18n.gettext('Este número de teléfono no está asociado a una cuenta'),
    missing_response: () => i18n.gettext('Completa este dato.'),
    user_required: () => i18n.gettext('Completa este dato.'),
    user_incomplete_registration: () => {
      const settings = {
        tags: {
          0: '<a class="input-error__link" onClick="window.onIncompleteRegistrationClick()">',
          1: '</a>',
        },
      };

      return i18n.jsx.gettext(
        'Tu cuenta todavía no existe. {0}Completa los pasos{1} para terminar de crearla.',
        settings,
      );
    },
    captcha_word_required: () => i18n.gettext('Completa el reCAPTCHA.'),
    captcha_response_invalid: () => i18n.gettext('Completa el reCAPTCHA.'),
    invalid_user_site: () => i18n.gettext('Ingresaste un e-mail o usuario de '),
    /* i18n-next-line except: ["en"] */
    user_site_platform_mismatch: () => i18n.gettext('Ingresaste un e-mail o usuario de otra plataforma.'),
    operator_not_allowed: () => i18n.gettext('Como operador de la cuenta, no tienes permiso para ingresar.'),
    server_error: () => i18n.gettext('¡Ups! Hubo un problema. Vuelve a intentarlo.'),
    /* i18n-block-start except: ["en", "pt-BR"] */
    email_invalid: () => i18n.gettext('El dato que ingresaste no es un e-mail.'),
    email_mismatch: () => i18n.gettext('Este e-mail no coincide con el de tu cuenta.'),
    social_error: () => i18n.gettext('Ocurrió un error, vuelve a intentarlo.'),
    /* i18n-block-end */
  },
  faqs_section: {
    /* i18n-block-start except: ["en"] */
    link_to_login_faqs: () => i18n.gettext('Necesito ayuda para ingresar'),
  },
  recaptcha: {
    label: {
      identification: i18n.gettext('Completa este paso para continuar'),
      password: i18n.gettext('Completa esta paso para iniciar sesión'),
      /* i18n-block-end */
    },
    protected_by: () => i18n.gettext('Protegido por reCAPTCHA'),
    privacy: () => i18n.gettext('Privacidad'),
    conditions: () => i18n.gettext('Condiciones'),
  },
  notFound: {
    titleMp: i18n.gettext('Lo que querías ver no existe o lo movimos de lugar'),
    titleMl: i18n.gettext('Parece que esta página no existe'),
    mpLink: i18n.gettext('Ver operaciones'),
  },
  serverError: {
    titleMp: i18n.gettext('Oh, no, algo anduvo mal'),
    titleMl: i18n.gettext('¡Ups! Hubo un error'),
    descriptionMp: i18n.gettext('Ya empezamos a trabajar en solucionarlo.'),
    descriptionMl: i18n.gettext('Inténtalo de nuevo más tarde'),
  },
  emailAddressVerification: {
    /* i18n-next-line except: ["en"] */
    title: i18n.gettext('Ingresa el e-mail asociado a tu cuenta'),
    label: i18n.gettext('E-mail'),
    action: i18n.gettext('Continuar'),
  },
  footer: {
    link: () => i18n.gettext('Cómo cuidamos tu privacidad (abrirá una nueva ventana)'),
    copyright: (year, isIfpe) => (
      isIfpe
        /* i18n-next-line except: ["en", "pt-BR"] */
        ? i18n.gettext('Este Canal de Instrucción o ambiente es operado por Mercado Libre, S.A. de C.V., Institución de Fondos de Pago Electrónico, sociedad autorizada para operar como institución de fondos de pago electrónico que proporciona los servicios relacionados con tu cuenta Mercado Pago Wallet.')
        : i18n.gettext('Copyright © 1999-{0} MercadoLibre S.R.L.', year) /* i18n-this-line except: ["en"] */
    ),
  },
  cameraNotFound: {
    /* i18n-block-start except: ["en"] */
    title: i18n.gettext('No pudimos reconocer la cámara de tu dispositivo'),
    subtitle: i18n.gettext('Ingresa desde otro dispositivo con cámara para poder usar el reconocimiento facial como método de verificación.'),

  },
  appVersionUnsupported: {
    title: i18n.gettext('Por favor, actualiza la app para continuar'),
    buttonUpdateApp: i18n.gettext('Actualizar app'),
  },
  browserNotSupported: {
    title: i18n.gettext('Para ingresar a tu cuenta, continúa desde Safari'),
  /* i18n-block-end */
  },
  o: i18n.gettext('o'),
});

module.exports.wordings = i18n => assembleWording(i18n);
exports.defaultWording = defaultWording;
exports.defaultPassword = defaultPassword;
exports.actionsForReauthPasswordPage = actionsForReauthPasswordPage;
exports.actionsForPasswordPage = actionsForPasswordPage;
exports.defaultMshopStoreWording = defaultMshopStoreWording;
