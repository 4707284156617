const React = require('react');
const { string } = require('prop-types');

const TextHeader = ({ title, subtitle }) => (
  <>
    <h1 className="center-card__title">{title}</h1>
    {subtitle && <p className="center-card__subtitle">{subtitle}</p>}
  </>
);

TextHeader.propTypes = {
  title: string.isRequired,
  subtitle: string,
};

TextHeader.defaultProps = {
  subtitle: '',
};

module.exports = TextHeader;
