import { loginTypes } from '../../services/data/enums';
import { help } from './help';
import {
  actionsForPasswordPage,
  actionsForReauthPasswordPage,
  defaultMshopStoreWording,
  defaultPassword,
  defaultWording,
} from './new-experience';
import { translateSecurityIssues } from './securityIssues';

const getWordingsPerContext = (i18n) => ({
  registered: {
    default: defaultWording(i18n, 'default').registered.password,
    explicit: defaultWording(i18n, 'login').registered.password,
    registration_v0: defaultWording(i18n, 'registration_v0').registered.password,
    registration_v3: defaultWording(i18n, 'registration_v3').registered.password,
    favorite: defaultPassword(i18n),
    question: defaultPassword(i18n),
    quote: defaultPassword(i18n),
    share: defaultPassword(i18n),
    print: defaultPassword(i18n),
    publish: defaultPassword(i18n),
    download: defaultPassword(i18n),
    contact: defaultPassword(i18n),
    sell: defaultPassword(i18n),
    buy: defaultPassword(i18n),
    view_cart: defaultPassword(i18n),
    add_cart: defaultPassword(i18n),
    checkout_buy: defaultPassword(i18n),
    checkout_auction: defaultPassword(i18n),
    advance_real_state: defaultPassword(i18n),
    advance_services: defaultPassword(i18n),
    advance_motors: defaultPassword(i18n),
    callme_real_state: defaultPassword(i18n),
    point_redeem_coupon: defaultWording(i18n, 'point_redeem_coupon').registered.password,
    google_play: defaultWording(i18n, 'google_play').registered.password,
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store').password,
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk').password,
    consumer_credits: defaultPassword(i18n),
    negative_traffic: defaultPassword(i18n),
    cbt: defaultWording(i18n, 'cbt').registered.password,
    point_buy: {
      buttons: actionsForReauthPasswordPage(i18n),
      title: defaultPassword(i18n).title,
    },
    vis_motor_contact: defaultWording(i18n, 'vis_motor_contact').registered.password,
    vis_credits_simulation: defaultPassword(i18n),
    faqs: defaultWording(i18n, 'faqs').registered.password,
    digital_sus: {
      /* i18n-next-line except: ["en"] */
      title: (_, platformName) => i18n.gettext(
        'Para activar tus beneficios, ingresa tu contraseña de {0}',
        platformName,
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    ifpe: {
      /* i18n-next-line except: ["en"] */
      title: (_, platformName) => i18n.gettext(
        'Para activar tus beneficios, ingresa tu contraseña de {0}',
        platformName,
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    registration_link: defaultWording(i18n, 'registration_link').registered.password,
    checkout_pro: defaultWording(i18n, 'checkout_pro').registered.password,
    [loginTypes.SCP_WALLET]: {
      /* i18n-block-start except: ["en", "pt-BR"]  */
      title: () => i18n.settings.isOnlyScopedLogin
        ? i18n.gettext('Usa tu contraseña para ingresar a Mercado Pago Wallet')
        : i18n.gettext('Ingresa tu contraseña de {0}', i18n.settings.replaceArguments.platformName),
      buttons: {
        /* i18n-block-end */
        submit: () => i18n.gettext('Continuar'),
        /* i18n-next-line except: ["pt-BR", "en"] */
        recover_pass: () => i18n.gettext('Usar otro método'),
      },
    },
  },
  not_registered: {
    default: defaultWording(i18n, 'default').not_registered.password,
    explicit: defaultWording(i18n, 'login').not_registered.password,
    registration_v0: defaultWording(i18n, 'registration_v0').not_registered.password,
    registration_v3: defaultWording(i18n, 'registration_v3').not_registered.password,
    favorite: defaultPassword(i18n),
    question: defaultPassword(i18n),
    quote: defaultPassword(i18n),
    share: defaultPassword(i18n),
    print: defaultPassword(i18n),
    publish: defaultPassword(i18n),
    download: defaultPassword(i18n),
    contact: defaultPassword(i18n),
    sell: defaultPassword(i18n),
    buy: defaultPassword(i18n),
    view_cart: defaultPassword(i18n),
    add_cart: defaultPassword(i18n),
    checkout_buy: defaultPassword(i18n),
    checkout_auction: defaultPassword(i18n),
    advance_real_state: defaultPassword(i18n),
    advance_services: defaultPassword(i18n),
    advance_motors: defaultPassword(i18n),
    callme_real_state: defaultPassword(i18n),
    point_redeem_coupon: defaultPassword(i18n),
    google_play: defaultPassword(i18n),
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store').password,
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk').password,
    consumer_credits: defaultPassword(i18n),
    negative_traffic: defaultPassword(i18n),
    cbt: defaultWording(i18n, 'cbt').not_registered.password,
    point_buy: {
      buttons: actionsForReauthPasswordPage(i18n),
      title: defaultPassword(i18n).title,
    },
    vis_motor_contact: defaultPassword(i18n),
    vis_credits_simulation: defaultPassword(i18n),
    faqs: defaultWording(i18n, 'faqs').not_registered.password,
    digital_sus: {
      title: defaultPassword(i18n),
      buttons: actionsForPasswordPage(i18n),
    },
    ifpe: {
      /* i18n-next-line except: ["en", "pt-BR"] */
      title: () => i18n.gettext(
        '¡Hola! Para seguir a Mercado Pago Wallet ingresa tu contraseña',
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    registration_link: defaultWording(i18n, 'registration_link').not_registered.password,
    checkout_pro: defaultWording(i18n, 'checkout_pro').not_registered.password,
    [loginTypes.SCP_WALLET]: {
      title: () => i18n.settings.isOnlyScopedLogin
        /* i18n-next-line except: ["en", "pt-BR"] */
        ? i18n.gettext('Usa tu contraseña para ingresar a Mercado Pago Wallet')
        : i18n.gettext('Ingresa tu contraseña de {0}', i18n.settings.replaceArguments.platformName),
      buttons: {
        submit: () => i18n.gettext('Continuar'),
        /* i18n-next-line except: ["pt-BR", "en"] */
        recover_pass: () => i18n.gettext('Usar otro método'),
      },
    },
  },
  password_greet: {
    default: defaultWording(i18n, 'default').password_greet.password,
    explicit: defaultWording(i18n, 'login').password_greet.password,
    registration_v0: defaultWording(i18n, 'registration_v0').password_greet.password,
    registration_v3: defaultWording(i18n, 'registration_v3').password_greet.password,
    registration_v0_legacy: defaultWording(i18n, 'registration_v0_legacy').password_greet.password,
    registration_v0_pj: defaultWording(i18n, 'registration_v0_pj').password_greet.password,
    favorite: {
      /* i18n-block-start except: ["en"] */
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para agregar favoritos, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    question: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para preguntar, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    quote: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para cotizar, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    share: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para compartir, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    print: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para imprimir, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    publish: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para imprimir, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    download: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para descargar, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    contact: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para contactar, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    sell: {
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Para vender, ingresa tu contraseña de {1}', [
        user,
        platformName,
      ]),
      buttons: actionsForPasswordPage(i18n),
    },
    buy: {
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Para comprar, ingresa tu contraseña de {1}', [
        user,
        platformName,
      ]),
      buttons: actionsForPasswordPage(i18n),
    },
    view_cart: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para ver el carrito, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    add_cart: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para agregar al carrito, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    checkout_buy: {
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Para comprar, ingresa tu contraseña de {1}', [
        user,
        platformName,
      ]),
      buttons: actionsForPasswordPage(i18n),
    },
    checkout_auction: {
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Para ofertar, ingresa tu contraseña de {1}', [
        user,
        platformName,
      ]),
      buttons: actionsForPasswordPage(i18n),
    },
    advance_real_state: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para reservar, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    advance_services: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para reservar, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    advance_motors: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para reservar, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    callme_real_state: {
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para pedir que te llamen, ingresa tu contraseña de {1}',
        /* i18n-block-end */
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    point_redeem_coupon: {
      /* i18n-next-line except: ["pt-BR", "en", "es-AR"] */
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Para seguir, ingresa tu contraseña de {1}', [
        user,
        platformName,
      ]),
      buttons: actionsForPasswordPage(i18n),
    },
    google_play: {
      /* i18n-next-line except: ["en"] */
      title: (user) => i18n.gettext(
        '¡Hola {0}! Ingresa tu contraseña para pagar en Google con Mercado Pago',
        user,
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    buy_ms_store: {
      /* i18n-next-line except: ["en"] */
      payment_and_shipping: i18n.gettext(
        'Procesamos el pago y envío con tu cuenta de',
      ),
      title: (user) => i18n.gettext(
        'Ingresa la contraseña de tu cuenta de Mercado Libre',
        user,
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    mshops_high_risk: {
      /* i18n-next-line except: ["en"] */
      payment_and_shipping: i18n.gettext(
        'Procesamos el pago y envío con tu cuenta de',
      ),
      title: (user) => i18n.gettext(
        'Ingresa la contraseña de tu cuenta de Mercado Libre',
        user,
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    consumer_credits: {
      /* i18n-next-line except: ["en"] */
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para activar Mercado Crédito, primero ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    negative_traffic: {
      /* i18n-next-line except: ["en"] */
      title: () => i18n.gettext('Para continuar ingresa tu contraseña'),
      buttons: actionsForPasswordPage(i18n),
    },
    cbt: defaultWording(i18n, 'cbt').password_greet.password,
    point_buy: {
      /* i18n-next-line except: ["en"] */
      title: (user, platformName) => i18n.gettext('¡Hola, {0}! Para seguir, ingresa tu contraseña de {1}', [
        user,
        platformName,
      ]),
      buttons: actionsForReauthPasswordPage(i18n),
    },
    vis_motor_contact: defaultWording(i18n, 'vis_motor_contact').password_greet.password,
    vis_credits_simulation: {
      /* i18n-next-line except: ["en"] */
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para simular tu crédito, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    faqs: defaultWording(i18n, 'faqs').password_greet.password,
    digital_sus: {
      /* i18n-next-line except: ["en"] */
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para activar tus beneficios, ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    ifpe: {
      /* i18n-next-line except: ["pt-BR", "en", "es-AR"] */
      title: (user, platformName) => i18n.gettext(
        '¡Hola, {0}! Para seguir a Mercado Pago Wallet ingresa tu contraseña de {1}',
        [user, platformName],
      ),
      buttons: actionsForPasswordPage(i18n),
    },
    registration_link: defaultWording(i18n, 'registration_link').password_greet.password,
    checkout_pro: defaultWording(i18n, 'checkout_pro').password_greet.password,
  },
  password_reauth: {
    default: defaultWording(i18n, 'default').password_reauth.password,
    registration_v0: defaultWording(i18n, 'registration_v0').password_reauth.password,
    registration_v3: defaultWording(i18n, 'registration_v3').password_reauth.password,
    google_play: {
      /* i18n-next-line except: ["en"] */
      title: (user) => i18n.gettext(
        '¡Hola {0}! Ingresa tu contraseña para pagar en Google con Mercado Pago',
        user,
      ),
      buttons: actionsForPasswordPage(i18n),
    },
  },
});
/**
 * @param  {Object} i18n
 * @param  {Object} i18n.settings
 * @param  {string} i18n.settings.loginType
 * @param  {keyof ReturnType<getWordingsPerContext>} i18n.settings.context
 * @param  {{userName: string, platformName: string}} i18n.settings.replaceArguments
 */
export const translatePasswordPage = (i18n) => {
  const { replaceArguments, context, loginType } = i18n.settings;
  const wordings = getWordingsPerContext(i18n)[context];
  const { title, buttons } = wordings[loginType] ?? wordings.default;

  return {
    title: title(replaceArguments.userName, replaceArguments.platformName),
    actions: {
      submit: buttons.submit(),
      recoverPass: buttons.recover_pass(),
    },
    labels: {
      password: i18n.gettext('Contraseña'),
      show: i18n.gettext('Mostrar'),
      hide: i18n.gettext('Ocultar'),
    },
    errors: {
      missing_response: i18n.gettext('Completa este dato.'),
      password_invalid: i18n.gettext('Revisa tu contraseña.'),
      password_required: i18n.gettext('Completa este dato.'),
      /* i18n-block-start except: ["en"] */
      captcha_word_required: i18n.gettext('Completa el reCAPTCHA.'),
      captcha_response_invalid: i18n.gettext('Completa el reCAPTCHA.'),
      /* i18n-block-end */
      operator_not_allowed: i18n.gettext(
        'Como operador de la cuenta, no tienes permiso para ingresar.',
      ),
      server_error: i18n.gettext(
        '¡Ups! Hubo un problema. Vuelve a intentarlo.',
      ),
    },
    changeUser: i18n.gettext('Cambiar cuenta'),
    helpSection: {
      label: help(i18n).label(),
      labelV2: help(i18n).labelV2(),
      title: help(i18n).title(),
    },
    securityIssues: translateSecurityIssues(i18n),
    recaptcha: {
      /* i18n-next-line except: ["en"] */
      label: i18n.gettext('Completa esta paso para iniciar sesión'),
    },
  };
};
