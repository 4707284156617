const React = require('react');
const PropTypes = require('prop-types');
const Script = require('nordic/script');

const MercadoShopsScripts = ({ mShopsScripts = [] }) => mShopsScripts.map(({ key, src, code, priority }) => <Script key={key} src={src} priority={priority} inline>{code}</Script>);

MercadoShopsScripts.propTypes = {
  mShopsScripts: PropTypes.arrayOf(PropTypes.shape()),
};

MercadoShopsScripts.defaultProps = {
  mShopsScripts: [],
};

module.exports = MercadoShopsScripts;
