import { AuthFlow, LoginVersion, VIEW_MODES } from '../data/enums';

/**
 * @param  {"changeAccount" | "help" | "securityProblem"} track
 * @param  {import("../data/navigation").Navigation & import("../data/challengeSchema").ChallengeSchema} context
 * @return {import('./trackEvent').LoginAuthChallengeEventData}
 */
const getEventData = (track, context) => {
  const { schema, navigation, visibilityPassword } = context;

  const { trackingId: tracking_id } = schema.transaction;

  const commonData = {
    challenge: schema.type,
    tracking_id,
  };
  const source = navigation?.loginType.value;
  const version = schema.content_configuration?.layout_view_mode === VIEW_MODES.landscape && {
    version: LoginVersion.v2,
  };

  const event = {
    changeAccount: {
      ...commonData,
      ...version,
      source,
      reauthentication: navigation?.authFlow === AuthFlow.REAUTH,
    },
    help: {
      ...commonData,
      ...version,
      source,
    },
    securityProblem: {
      ...commonData,
      code: 'security_problem',
    },
    passwordVisibility: {
      ...commonData,
      visibility_status: visibilityPassword,
    },
    googleAuthClick: {
      tracking_id,
      source: 'login',
      social_app: 'google',
    },
    createAccount: {
      ...commonData,
      source,
    },
  };

  return event[track];
};

const trackPath = {
  changeAccount: '/login/auth/challenge/change_account',
  help: '/login/auth/challenge/help',
  securityProblem: '/login/challenge/complaint/execute',
  passwordVisibility: '/login/auth/challenge/password_visibility',
  googleAuthClick: '/auth/social_account/google_button',
  createAccount: '/login/auth/challenge/create_account',
};

/**
* @param  {"changeAccount" | "help" | "securityProblem"} track
* @param  {import("../data/navigation").Navigation & import("../data/challengeSchema").ChallengeSchema} context
*/
const getTrackingData = (track, context) => ({
  path: trackPath[track],
  event_data: getEventData(track, context),
});

export default { getTrackingData, trackPath };
